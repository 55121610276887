// import { navigate } from "gatsby"

import { Button } from "antd"
import React, { useContext, useState } from "react"
import { AccountContext } from "../../../context/account"
import { signUp } from "../../../services/user"
import "../style.scss"
import LabelAndValueInputed from "./labelAndValueInputed"
import { navigate } from "gatsby"
import { formatPhoneNumber } from "react-phone-number-input"
import arrayFormat from "../../../common/arrayUseInProject/arrayFormat"

const DisplayConfirmSignUp = ({
  dataInput,
  setConfirmSignUp,
}: {
  dataInput: any
  setConfirmSignUp: any
}) => {
  const [signUpCompleted, setSignUpCompleted] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [messageError, setMessageError] = useState("")
  const { regions } = useContext(AccountContext)

  const handleSignUp = async () => {
    setIsLoading(true)
    const dataSent: any = {
      email: dataInput.email,
      first_name: dataInput.manage.surname,
      last_name: dataInput.manage.name,
      metadata: {
        company_name: dataInput.companyName,
        department: dataInput.building_name,
        store_name: dataInput.storeName,
        phone: dataInput.phoneNumber,
        zipCode: dataInput.number1 + dataInput.number2,
        address: {
          num1: dataInput.number1,
          num2: dataInput.number2,
          region: dataInput.prefecture,
          town: dataInput.town,
          city: dataInput.city,
          building_name: dataInput.building_name,
        },
      },
      user_type:
        (dataInput.format == 1 && "SELLER") ||
        (dataInput.format == 3 && "SUPPLIER") ||
        null,
    }
    const { response }: any = await signUp(dataSent)
    if (response.response?.status == 422) {
      setIsLoading(false)
      setMessageError(
        "指定された電子メールを持つ顧客は、すでにアカウントを持っています。別のメールアドレスを入力してください。"
      )
    }
    if (response.status == 200) {
      console.log(response.data)
      // handleLogin(response.data.user)
      // dispatch({ type: "userLoggedIn", payload: response.data.user })
      setIsLoading(false)
      setSignUpCompleted(true)
    } else {
      setIsLoading(false)
      setMessageError("アカウントの新規登録を失敗しました。")
    }
  }
  return (
    <div className="flex h-full w-full  justify-center mt-14 mb-32">
      <div
        className="flex min-h-[600px]  bg-grey-0 rounded-rounded justify-center flex-col"
        style={{ minWidth: 320, width: 820 }}
      >
        {messageError && (
          <div
            className={
              "flex w-97/100 justify-center bg-[#e60020] m-5 text-white rounded-[5px]  py-4"
            }
          >
            {messageError}
          </div>
        )}
        {!signUpCompleted && (
          <div className="flex flex-col pt-12 w-full px-[80px] ">
            <LabelAndValueInputed
              label="業態"
              value={
                arrayFormat.filter((el) => el.id == dataInput?.format)[0]?.name
              }
            />
            <LabelAndValueInputed
              label="会社名"
              value={dataInput.companyName}
            />
            <LabelAndValueInputed
              label="ご担当者"
              value={dataInput.manage.surname + " " + dataInput.manage.name}
            />
            {dataInput.format == 2 && (
              <LabelAndValueInputed
                label="店舗名"
                value={dataInput.storeName}
              />
            )}
            <LabelAndValueInputed
              label="住所"
              value={`〒${dataInput.number1}${dataInput.number2} ${
                dataInput.prefecture
              } ${dataInput.city} ${dataInput.town} ${
                dataInput.building_name ? dataInput.building_name : ""
              }`}
            />
            <LabelAndValueInputed
              label="電話番号"
              value={formatPhoneNumber(dataInput.phoneNumber)}
            />
            <LabelAndValueInputed
              label="メールアドレス"
              value={dataInput.email}
            />
            <div className="flex-row flex gap-[10px] justify-center">
              <div>
                <Button
                  type="text"
                  style={{ backgroundColor: "#E4E8EB" }}
                  className="rounded bg-[#E4E8EB] font-medium text-[#333] rounded-[5px] mt-3 mb-14 focus:bg-violet-100 hover:bg-violet-100 h-[37px]  inter-base-regular"
                  onClick={() => {
                    setConfirmSignUp(false)
                  }}
                >
                  前に戻る
                </Button>
              </div>
              <div>
                <Button
                  type="primary"
                  loading={isLoading}
                  className="rounded bg-[#E60020] font-medium text-[#fff] focus:bg-rose-500 hover:bg-rose-500 rounded-[5px] mt-3 mb-[32px] h-[37px] inter-base-regular"
                  onClick={() => {
                    handleSignUp()
                  }}
                >
                  登録する
                </Button>
              </div>
            </div>
          </div>
        )}
        {signUpCompleted && (
          <div className="flex flex-col pt-12 w-full px-[150px] ">
            <div className="flex flex-row gap-[7px] justify-center items-center">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="14"
                  cy="14"
                  r="13"
                  fill="white"
                  stroke="#EA6D69"
                  strokeWidth="2"
                />
                <path
                  d="M6.2998 14L11.1998 18.9L20.9998 9.09998"
                  stroke="#EA6D69"
                  strokeWidth="3"
                />
              </svg>
              <div className="text-[22px] font-bold text-rose-400">
                ご登録ありがとうございます。
              </div>
            </div>
            <div className="pt-9">
              <div>この度は、会員登録頂きまして誠にありがとうございます。</div>
              <div>
                会員情報はご登録頂いたメールアドレスに送付しておりますので、
              </div>
              <div>大切に保管してください。</div>
              <div>
                マイページから配送先情報をご登録頂くとスムーズに仕入れが可能になります。
              </div>
              <div>
                また、商品登録を行うことで既存顧客以外にも貴社商品を販売することが可能
              </div>
              になりますので、是非新しい販路として当サービスをご活用ください。
            </div>
            <div className="flex flex-row gap-[8px] pt-8">
              <div style={{ wordSpacing: 19 }}>ID :</div>
              <div className="text-rose-400">{dataInput?.email}</div>
            </div>
            <div className="flex flex-row gap-[8px]">
              <div>PASS :</div>
              <div>********</div>
            </div>
            <div className="flex flex-col gap-[8px] pt-8">
              <div>※ID、PASSは下記よりご変更頂けます。</div>
              <div className="flex flex-row gap-[10px]">
                <div
                  className="text-rose-500 border-b border-rose-400 cursor-pointer"
                  onClick={() => {
                    navigate("/")
                  }}
                >
                  ログイン
                </div>
                <div>{">"}</div>
                <div className="text-rose-500 border-b border-rose-400 cursor-pointer">
                  会社情報登録
                </div>
              </div>
            </div>
            <div className="line_full_width_56  mt-14"></div>

            <div className="flex justify-center">
              <Button
                style={{
                  width: "fit-content",
                  fontSize: 13,
                  borderRadius: 5,
                  minWidth: 320,
                  borderColor: "red",
                  color: "red",
                }}
              >
                操作マニュアルをダウンロードする
              </Button>
            </div>

            <div className="flex justify-center mt-4">
              <Button
                type="primary"
                className="rounded bg-[#E60020] text-[#fff] focus:bg-rose-500 hover:bg-rose-500 rounded-[5px] mt-3 w-[320px] inter-base-regular mb-[32px]"
                size="large"
                onClick={() => {
                  navigate("/")
                }}
              >
                ログインページへ
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DisplayConfirmSignUp
