// import { navigate } from "gatsby"

import React, { useEffect, useRef, useState } from "react"
import "./style.scss"

import { Form, Button, Checkbox, Space } from "antd"
import FormTextArea from "../../components/formTextArea"
import FormSelect from "../../components/formSelect/FormSelect"
import InputSurnameAndName from "./formInputName"
import Input from "react-phone-number-input/input"
import { formatPhoneNumber, isValidPhoneNumber } from "react-phone-number-input"
import FormItemAddress from "./components/FormItemAddress"
import arrayFormat from "../../common/arrayUseInProject/arrayFormat"

const DisplaySignUp = ({
  setConfirmSignUp,
  setDataInput,
  dataInput,
  location,
}) => {
  const ref = useRef()
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [agree, setAgree] = useState(false)
  const urlAdmin = process.env.GATSBY_NEXT_PUBLIC_URL_SIGN_UP_ADMIN
  const params = new URLSearchParams(location.search)
  const parameter1 = params.get("format")
  const [inputPhone, setInputPhone] = useState(
    formatPhoneNumber(dataInput?.phoneNumber) || null
  )

  useEffect(() => {
    if (parameter1 == 3) {
      form.setFieldsValue({ format: 3 })
    }
  }, [parameter1])

  const handleChangeSelectFormat = (e) => {
    console.log(e)
    if (e == 2) {
      window.open(
        `${urlAdmin}/sign-up`,
        "_blank" // <- This is what makes it open in a new window.
      )
      form.setFieldsValue({ format: 1 })
      return
    }
  }

  const checkNameAndSurname = (_, value) => {
    if (value?.surname?.trim() && value?.name?.trim()) {
      return Promise.resolve()
    }
    return Promise.reject(new Error("入力してください。"))
  }

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields[0].name[0] == "manage") {
      form.scrollToField(["storeName"], {
        block: "end",
        scrollMode: "center",
        behavior: "smooth",
      })
    }
    form.scrollToField(errorFields[0].name, {
      block: "center",
      scrollMode: "center",
      behavior: "smooth",
    })
  }

  const onFinish = async (values) => {
    console.log(values)
    if (!checked) {
      setAgree(true)
      return
    }
    setDataInput(values)
    setConfirmSignUp(true)
  }
  const submitForm = () => {
    if (form) {
      form.submit()
    }
  }
  const checkInputPhoneNumber = (_, value) => {
    if (value) {
      if (isValidPhoneNumber(value)) {
        const array = formatPhoneNumber(value).split("")
        if (array[1] == "0") {
          return Promise.reject(new Error("有効な電話番号を入力してください。"))
        }
        if (array[0] !== inputPhone?.split("")[0]) {
          return Promise.reject(new Error("有効な電話番号を入力してください。"))
        }
        if (array[1] !== inputPhone?.split("")[1]) {
          return Promise.reject(new Error("有効な電話番号を入力してください。"))
        }
        return Promise.resolve()
      }
      return Promise.reject(new Error("有効な電話番号を入力してください。"))
    }
    return Promise.reject(new Error(""))
  }

  return (
    <div className="flex h-full w-full  justify-center mt-14 mb-32">
      <div
        className="flex min-h-[600px] bg-grey-0 rounded-rounded justify-center flex-col form_signup"
        style={{ minWidth: 320, maxWidth: 820 }}
      >
        <Form
          colon={false}
          className="flex flex-col pt-12 w-full px-[80px] items-center fix_ant_item"
          name="basic"
          initialValues={{
            format: dataInput?.format || 1,
            companyName: dataInput?.companyName,
            manage: {
              name: dataInput?.manage?.name,
              surname: dataInput?.manage?.surname,
            },
            phoneNumber: dataInput?.phoneNumber,
            email: dataInput?.email,
            storeName: dataInput?.storeName,
            number1: dataInput?.number1,
            number2: dataInput?.number2,
            prefecture: dataInput?.prefecture,
            city: dataInput?.city,
            town: dataInput?.town,
            building_name: dataInput?.building_name,
          }}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 19 }}
          labelAlign="left"
          onChange={(e) => {
            // console.log(e)
            if (e.target.id == "basic_phoneNumber") {
              if (e.target.value) {
                setInputPhone(e.target.value)
              }
            }
            setLoading(false)
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          ref={ref}
        >
          <FormSelect
            name="format"
            labelName="業態"
            required={true}
            option={arrayFormat}
            placeholder={"業態"}
            handleChange={handleChangeSelectFormat}
          />
          <div className="line_full_width"></div>
          <FormTextArea
            name="companyName"
            labelName="会社名"
            required={true}
            placeEmail={false}
            placeholder={"株式会社なめたろう"}
          />
          <div className="line_full_width"></div>

          <Form.Item
            name="manage"
            label={
              <div className="flex gap-[14px]">
                <div className="bg-[#FFF1F0] text-[#E75B53] text-[11px] rounded-[5px] p-[3px]">
                  {"必須"}
                </div>
                <div className="font-bold text-[14px]">{"ご担当者"}</div>
              </div>
            }
            rules={[
              {
                validator: checkNameAndSurname,
              },
            ]}
          >
            <InputSurnameAndName />
          </Form.Item>
          <div className="line_full_width"></div>
          <FormItemAddress form={form} />
          <div className="line_full_width"></div>
          <Form.Item
            label={
              <Space size={14}>
                <div
                  style={{
                    background: "#FFF1F0",
                    color: "#E75B53",
                    fontSize: 11,
                    fontWeight: 500,
                    borderRadius: 5,
                    padding: 3,
                  }}
                >
                  {"必須"}{" "}
                </div>
                <div className="font-bold text-[14px]">{"電話番号"}</div>
              </Space>
            }
          >
            <Form.Item
              name={"phoneNumber"}
              rules={[
                {
                  required: true,
                  message: `電話番号を入力してください。`,
                },
                {
                  validator: checkInputPhoneNumber,
                },
              ]}
            >
              <Input
                country="JP"
                placeholder={"000-0000-0000"}
                className="inputNumberPhone"
              />
            </Form.Item>
          </Form.Item>
          <div className="line_full_width"></div>
          <FormTextArea
            name="email"
            labelName="メールアドレス"
            required={true}
            placeholder={"sample@nametarou.com"}
            placeEmail={true}
          />

          <div className="line_full_width"></div>
        </Form>
        <div className="flex justify-center items-center flex-col">
          <div>
            ※一般個人の方はご利用できないサービスです。（飲食店を営む個人事業主は利用できます）
          </div>
          <div className="text-red-500">
            また当社は反社会的勢力との取引は一切いたしません。
          </div>
          <div
            className="flex flex-row gap-[8px] mt-11 p-1 rounded"
            style={{ border: agree ? "1px solid red" : "none" }}
          >
            <Checkbox
              checked={checked}
              onChange={(e) => {
                setAgree(false)
                setChecked(e.target.checked)
              }}
            />
            <div className="flex flex-row">
              上記および
              <div
                className="text-red-500"
                style={{ borderBottom: "1px solid ", cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    `${process.env.GATSBY_NEXT_PUBLIC_URL_STORE}/terms-of-service`,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }}
              >
                利用規約
              </div>
              に同意します
            </div>
          </div>
          {agree && <div className="text-red-500">選択してください。</div>}
          <Button
            type="danger"
            className="rounded text-[#fff] rounded-[5px] mt-3 mb-14  w-[320px] inter-base-regular"
            size="large"
            onClick={submitForm}
            loading={loading}
          >
            無料会員登録内容の確認
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DisplaySignUp
