import React from "react"
import { Breadcrumb } from "antd"

const BreadcrumbSignIn = ({ confirmSignIn }: { confirmSignIn: boolean }) => {
  return (
    <Breadcrumb separator=">">
      <Breadcrumb.Item>TOP</Breadcrumb.Item>
      <Breadcrumb.Item>新規会員登録</Breadcrumb.Item>
      {confirmSignIn && <Breadcrumb.Item>確認</Breadcrumb.Item>}
    </Breadcrumb>
  )
}

export default BreadcrumbSignIn
